import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox, FormControlLabel } from '@mui/material';

export default function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [prenom, setPrenom] = useState('');
  const [message, setMessage] = useState('');
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isPwa, setIsPwa] = useState(false);
  const navigate = useNavigate();
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;
  const isIOS = /iphone|ipod/.test(navigator.userAgent.toLowerCase());
  const isIpadOS = /ipad/.test(navigator.userAgent.toLowerCase());
  const isMacOS = /macintosh|mac os x/.test(navigator.userAgent.toLowerCase()) && !window.navigator.standalone;
  const [openCGU, setOpenCGU] = useState(false); // Gère l'affichage du dialogue des CGU
  const [cguAccepted, setCguAccepted] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Détection spécifique à iOS pour le mode PWA
    if (window.navigator.standalone === true) {
      setIsPwa(true);
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);
  const validateForm = () => {
    // Validation du prénom
    if (!prenom) {
      setMessage('Le prénom est requis.');
      return false;
    }
  
    // Validation de l'email avec une expression régulière basique pour l'exemple
    const emailRegex = /\S+@\S+\.\S+/;
    if (!email || !emailRegex.test(email)) {
      setMessage('L\'email n\'est pas valide.');
      return false;
    }
  
    // Validation du mot de passe (exemple : au moins 6 caractères)
    if (!password || password.length < 6) {
      setMessage('Le mot de passe doit contenir au moins 6 caractères.');
      return false;
    }
  
    return true; // Tous les champs sont valides
  };
  
  const handleOpenCGU = () => {
    setOpenCGU(true);
  };
  const handleCloseCGU = () => {
    setOpenCGU(false);
  };
  const handleAcceptCGU = (event) => {
    setCguAccepted(event.target.checked);
  };
  useEffect(() => {
    if (isPwa) {
      navigate('/login');
    }
  }, [isPwa, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!cguAccepted) {
      alert('Vous devez accepter les CGU pour vous inscrire.');
      return;
    }
    if (!validateForm()) {
      return; // Arrêtez ici si la validation échoue
    }
    axios.post(`${apiUrl}/register`, { email, password, prenom })
      .then(response => {
        return axios.post(`${apiUrl}/login_check`, { username: email, password });
      })
      .then(response => {
        localStorage.setItem('token', response.data.token);
        navigate('/placard');
      })
      .catch(error => {
        console.error('Error during sign up or login:', error);
      });
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };


  return (
    <Container component="main" maxWidth="xxl" style={{ backgroundColor: '#ece0cc', minHeight: '100vh' }}>
      <Button onClick={() => navigate('/accueil')}><i className="fa-solid fa-arrow-left"></i> RETOUR</Button>
      <CssBaseline />
      <Box
        sx={{
          paddingTop: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>

        </Avatar>
        <Typography component="h1" variant="h5">
          S'inscrire
        </Typography>
        {message && <Typography color="error" sx={{ mt: 2 }}>{message}</Typography>}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="prenom"
                name="prenom"
                required
                fullWidth
                id="prenom"
                label="Prénom"
                autoFocus
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoComplete="email"
                name="email"
                required
                fullWidth
                id="email"
                label="Adresse Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
          </Grid>
          <Dialog open={openCGU} onClose={handleCloseCGU} scroll="paper" fullWidth maxWidth="md">
  <DialogTitle>Conditions Générales d'Utilisation</DialogTitle>
  <DialogContent dividers>
    <DialogContentText tabIndex={-1}>
      <p><strong>Bienvenue sur Stop Gaspi,</strong></p>
      <p>Le présent document a pour but de définir les termes et conditions sous lesquels d'une part, Maximuss Dev, ci-après dénommé le "Propriétaire", met à disposition de ses utilisateurs le site, et les services disponibles sur le site et d'autre part, la manière par laquelle l'utilisateur accède au site et utilise ses services.</p>

      <p><strong>1. Acceptation des Conditions Générales d'Utilisation</strong><br />
      En accédant et en utilisant Stop Gaspi, vous acceptez d'être lié par ces Conditions Générales d'Utilisation sans réserve. Si vous n'acceptez pas ces conditions, vous ne devez pas utiliser les services ou informations disponibles sur le site.</p>

      <p><strong>2. Description des Services</strong><br />
      Stop Gaspi fournit un service de gestion de stock alimentaire qui permet aux utilisateurs de surveiller et gérer la date de péremption de leurs produits alimentaires. Les informations relatives aux produits alimentaires proviennent de la base de données de Open Food Facts.</p>

      <p><strong>3. Données de Open Food Facts</strong><br />
      Nous utilisons les données fournies par Open Food Facts (world.openfoodfacts.org), un projet mondial à but non lucratif qui collecte des informations et des données sur les produits alimentaires du monde entier. Conformément à leur politique d'utilisation, nous indiquons clairement l'utilisation de leurs données dans notre service.</p>

      <p><strong>4. Propriété intellectuelle</strong><br />
      Le contenu du site Stop Gaspi, le design, le texte, les images, et tout autre matériel sont la propriété du "Propriétaire" ou sont utilisés avec l'autorisation des titulaires des droits.</p>

      <p><strong>5. Responsabilité de l'Utilisateur</strong><br />
      L'utilisateur s'engage à utiliser les services de Stop Gaspi dans le respect des lois et règlements en vigueur. Il est de la responsabilité de l'utilisateur de s'assurer que la gestion de son stock alimentaire respecte toutes les réglementations locales, nationales ou internationales pertinentes.</p>

      <p><strong>6. Limitation de responsabilité</strong><br />
      Maximuss Dev ne pourra être tenu responsable des dommages directs ou indirects causés au matériel de l'utilisateur, lors de l'accès au site Stop Gaspi. De plus, l'utilisation des données de Open Food Facts se fait à la discrétion de l'utilisateur et à ses propres risques.</p>

      <p><strong>7. Droit applicable et juridiction</strong><br />
      Ces conditions d'utilisation sont régies par et interprétées conformément aux lois du pays de résidence du "Propriétaire", sans donner effet à aucun principe de conflits de lois.</p>

      <p>Contactez-nous pour toute question ou information complémentaire sur les services proposés, ou concernant le site lui-même, vous pouvez nous laisser un message <Link href="mailto:maxime@maximuss-dev.com" color="inherit" underline="hover">ici</Link>. </p>
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <FormControlLabel
      control={<Checkbox checked={cguAccepted} onChange={handleAcceptCGU} name="acceptCGU" />}
      label="J'accepte les Conditions Générales d'Utilisation"
    />
    <Button onClick={handleCloseCGU} color="primary">
      Fermer
    </Button>
  </DialogActions>
</Dialog>

      {/* Ajoutez un bouton ou un lien pour ouvrir le dialogue des CGU */}
      <Button onClick={handleOpenCGU} color="primary">
        Lire et accepter les CGU
      </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            S'inscrire
          </Button>
          {deferredPrompt && (
            <Button
              fullWidth
              variant="outlined"
              onClick={handleInstallClick}
              sx={{ mt: 3, mb: 2 }}
            >
              Installer l'application
            </Button>
          )}
          {!deferredPrompt && isIOS && !isIpadOS && (
            <Typography sx={{ mt: 2, textAlign: 'center' }}>
              Pour installer cette application sur votre appareil iOS, appuyez sur l'icône de partage au bas de votre écran, puis sur "Ajouter à l'écran d'accueil".
            </Typography>
          )}
          {!deferredPrompt && isIpadOS && !isIOS && (
            <Typography sx={{ mt: 2, textAlign: 'center' }}>
              Pour installer cette application sur votre appareil iOS, appuyez sur l'icône de partage au bas de votre écran, puis sur "Ajouter à l'écran d'accueil".
            </Typography>
          )}
          {!deferredPrompt && !isIOS && !isIpadOS && isMacOS && (
            <Typography sx={{ mt: 2, textAlign: 'center' }}>
              Pour installer cette application sur votre appareil macOS, appuyez sur l'icône de partage au haut à droite de votre écran, puis sur "Ajouter au Dock".
            </Typography>
          )}

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="#" variant="body2" onClick={() => navigate('/login')}>
                Vous avez déjà un compte? Se connecter
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
