import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../AuthContext';

const theme = createTheme();


export default function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;
    const { login } = useAuth();

    const handleSubmit = (event) => {
        event.preventDefault();

        axios.post(`${apiUrl}/login_check`, { username: email, password })
            .then(response => {
                //console.log(response.data);
                localStorage.setItem('token', response.data.token);
                
                setMessage('Connexion réussie!');
                login(response.data.token);

                navigate('/home');
            })
            .catch(error => {
                console.error('Login error:', error);
                setMessage('Erreur lors de la connexion.');
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xxl" style={{ backgroundColor: '#ece0cc',minHeight: '100vh'}}>
                <Button onClick={() => navigate('/accueil')}><i className="fa-solid fa-arrow-left"></i> RETOUR</Button>
                <CssBaseline />
                <Box
                    sx={{
                        paddingTop: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Connexion
                    </Typography>
                    {message && <Typography color="error">{message}</Typography>}
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Adresse Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Mot de passe"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                       
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Se connecter
                        </Button>
                        <Grid container>
                            {/* <Grid item xs>
                                <Link href="#" variant="body2">
                                    Mot de passe oublié?
                                </Link>
                            </Grid> */}
                            <Grid item>
                                <Link href="#" variant="body2" onClick={() => navigate('/register')}>
                                    {"Vous n'avez pas de compte? S'inscrire"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
