import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import Home from './components/Home';
import AddProductPage from './components/AddProductPage';
import Accueil from './components/Accueil';
import Placard from './components/Placard';
import PlacardListe from './components/PlacardListe';
import Liste from './components/Liste';
import Nav from './components/Nav';
import Footer from './components/Footer';
import { useAuth } from './AuthContext';

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <Nav />
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/accueil" element={<Accueil />} />
        <Route path="/home" element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        } />
        <Route path="/add-product" element={
          <ProtectedRoute>
            <AddProductPage />
          </ProtectedRoute>
        } />
        <Route path="/placard" element={
          <ProtectedRoute>
            <Placard />
          </ProtectedRoute>
        } />
        <Route path="/placard-liste" element={
          <ProtectedRoute>
            <PlacardListe />
          </ProtectedRoute>
        } />
        <Route path="/liste" element={
          <ProtectedRoute>
            <Liste />
          </ProtectedRoute>
        } />
        {/* Fallback route for unauthorized access */}
        <Route path="*" element={isAuthenticated ? <Home /> : <LoginForm />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
