import React from 'react';
import { Container, Typography, Button, Accordion, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';

const Accueil = () => {
    const navigate = useNavigate();
    
    return (

        <Container maxWidth="xxl" style={{ backgroundColor: '#ece0cc' , minHeight: '95vh' }}>
            <section style={{ padding: '20px 0', textAlign: 'center' }}>

                <Typography variant="h4" style={{ fontFamily: '"Madimi One", sans-serif' }}>Bienvenue sur :</Typography>
                <Typography variant="h3" style={{ fontFamily: '"Madimi One", sans-serif' }}>Stop Gâspi</Typography>
                <Typography variant="h6" style={{ margin: '0px 0', fontFamily: '"Madimi One", sans-serif', fontSize: '16px' }}>
                    Suivez facilement les dates de péremption de vos produits.
                </Typography>
              
            </section>
            <section style={{ padding: '20px 0', textAlign: 'center' }}>
            <Typography style={{ textAlign: 'center', fontFamily: '"Madimi One", sans-serif' }}>Pourquoi utiliser Stop Gaspi ?</Typography>
            <Typography style={{ textAlign: 'center' , fontSize: '12px' }}>Pour éviter de jeter des produits périmés et pour mieux gérer vos stocks.</Typography>
            </section>
            <section style={{ padding: '20px 0', textAlign: 'center' }}>

            <Typography style={{ textAlign: 'center', fontFamily: '"Madimi One", sans-serif' }}>Cela va me prendre du temps ?</Typography>
            <Typography style={{ textAlign: 'center' , fontSize: '12px'}}>Non, Stop Gaspi est simple et rapide à utiliser. Et en plus, c'est bon pour le porte-monnaie et la planète !</Typography>
            </section>

            <Typography variant="h6" style={{ textAlign: 'center',marginTop: '50px', fontFamily: '"Madimi One", sans-serif'  }}>Que me permet de faire Stop Gaspi ?</Typography>



            <Grid container spacing={5} style={{ marginTop: '0px' }}>
                {/* Fonctionnalité 1 */}
                <Grid item xs={12} md={4}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" style={{ textAlign: 'center' }}>Fonctionnalité 1</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{ textAlign: 'center' }}>Contrôler les dates de péremption de vos produits et recevez des alertes.</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/* Fonctionnalité 2 */}
                <Grid item xs={12} md={4}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" style={{ textAlign: 'center' }}>Fonctionnalité 2</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{ textAlign: 'center' }}>Garder un oeil sur vos stocks et éviter les oublis lors de vos courses.</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {/* Fonctionnalité 3 */}
                <Grid item xs={12} md={4}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6" style={{ textAlign: 'center' }}>Fonctionnalité 3</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{ textAlign: 'center' }}>Partager vos listes de courses avec les membres de votre foyer. (Prochainement)</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <section style={{ padding: '20px 0', textAlign: 'center', marginTop: '30px' }}>

                <Button variant="contained" color="primary" onClick={() => navigate('/register')}>Commencer maintenant<br></br> (2 mois gratuits*)</Button>
                <Typography style={{ marginTop: '10px' }}>* Puis 9,99€/an</Typography>
                <Typography style={{ marginTop: '20px' }}>Déjà inscrit ? <Button color="primary" onClick={() => navigate('/login')}>Se connecter</Button></Typography>
            </section>
            
            
        </Container>

    );
};

export default Accueil;
