import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Typography,Grid } from '@mui/material';

function Home() {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [greeting, setGreeting] = useState('');
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    useEffect(() => {
        const fetchUserInfo = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${apiUrl}/user/info`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error(`Erreur HTTP: ${response.status}`);
                }
                const data = await response.json();
                setFirstName(capitalizeFirstLetter(data.firstName)); // Appliquer la capitalisation ici
            } catch (error) {
                console.error("Erreur lors de la récupération des informations utilisateur :", error);
            }
        };

        fetchUserInfo();

        // Déterminer si on doit dire Bonjour ou Bonsoir
        const hours = new Date().getHours();
        const greetingMessage = hours < 18 ? "Bonjour" : "Bonsoir";
        setGreeting(greetingMessage);
    }, []);

    return (
        <>
            <Container maxWidth="xl" style={{ backgroundColor: '#ece0cc', minHeight: '85vh' }}>
                <Typography variant="h4" gutterBottom style={{ textAlign: 'center', fontFamily: '"Madimi One", sans-serif' , paddingTop: '40px' , paddingBottom: '20px' }}>
                    {`${greeting} : ${firstName}`} 
                </Typography>
                <Grid  style={{ textAlign: 'center', marginTop: '50px' }}>
                <Button variant="contained" color="secondary" onClick={() => navigate('/liste')} style={{ textAlign: 'center', marginBottom: '20px' }}>
                    Consulter mes produits
                </Button>
                </Grid>
                <Grid  style={{ textAlign: 'center', marginTop: '50px' }}>

                <Button variant="contained" color="primary" onClick={() => navigate('/add-product')} style={{ textAlign: 'center', marginBottom: '20px' }}>
                    Ajouter un produit
                </Button>
                </Grid>
            </Container>
        </>
    );
}

export default Home;
