import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, TextField, Button, Box, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const PlacardsPage = () => {
    const [placards, setPlacards] = useState([]);
    const [nouveauPlacard, setNouveauPlacard] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [currentPlacardToDelete, setCurrentPlacardToDelete] = useState(null);
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchPlacards = async () => {
            const response = await axios.get(`${apiUrl}/placards`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            setPlacards(response.data['hydra:member']);
        };
        fetchPlacards();
    }, []);

    const handleDialogOpen = (placard) => {
        setCurrentPlacardToDelete(placard);
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDelete = async () => {
        // Suppression du placard
        try {
            await axios.delete(`${apiUrl}/placards/${currentPlacardToDelete.id}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            setPlacards(placards.filter(placard => placard.id !== currentPlacardToDelete.id));
            handleDialogClose();
        } catch (error) {
            console.error('Erreur lors de la suppression du placard:', error);
        }
    };

    return (
        <Container component="main" maxWidth="xxl" style={{ backgroundColor: '#ece0cc', minHeight: '100vh' }}>
            <Typography variant="h6" style={{ textAlign: 'center', fontFamily: '"Madimi One", sans-serif', paddingTop: '20px', paddingBottom: '20px' }}>
                Gérer vos placards
            </Typography>
            {/* Liste des placards existants */}
            {placards.map(placard => (
                <Box key={placard.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                    <Typography>{placard.endroit}</Typography>
                    <Button variant="outlined" color="error" onClick={() => handleDialogOpen(placard)}>
                        Supprimer
                    </Button>
                </Box>
            ))}
            {/* Formulaire pour ajouter un nouveau placard */}
            <Box component="form" noValidate sx={{ mt: 3 }}>
                <TextField
                    fullWidth
                    label="Nom du placard ou de l'endroit"
                    value={nouveauPlacard}
                    onChange={(e) => setNouveauPlacard(e.target.value)}
                    sx={{ mt: 2 }}
                />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Ajouter un placard
                </Button>
            </Box>
            {/* Boîte de dialogue de confirmation pour la suppression */}
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Supprimer ce placard ?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Cette action est irréversible. Êtes-vous sûr de vouloir supprimer ce placard ?<br />Tous les produits contenus dans ce placard seront également supprimés.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Annuler</Button>
                    <Button onClick={handleDelete} autoFocus>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default PlacardsPage;
