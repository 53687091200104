import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Ajustez le chemin d'importation selon votre structure

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
  
    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }
  
    return children;
  };
  

export default ProtectedRoute;
