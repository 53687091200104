import React, { useState, useEffect } from 'react';
import { Container, Typography, List, ListItem, ListItemText, Button } from '@mui/material';

function Liste() {
    const [products, setProducts] = useState([]);
    const [placardsInfo, setPlacardsInfo] = useState({});
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;

    const regrouperProduitsParPlacard = (produits) => {
        const placardsMap = {};

        produits.forEach((produit) => {
            const placardNom = produit.placard.endroit; // Utilisez le nom directement
            if (!placardsMap[placardNom]) {
                placardsMap[placardNom] = {
                    placardNom,
                    produits: []
                };
            }
            placardsMap[placardNom].produits.push(produit);
        });

        return Object.values(placardsMap); // Transforme l'objet en tableau de groupes par placard
    };
    useEffect(() => {
        const fetchPlacards = async () => {
            const response = await fetch(`${apiUrl}/placards`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                const placardsMap = data['hydra:member'].reduce((acc, placard) => {
                    acc[placard.id] = placard.endroit; // Assumer que 'endroit' est le champ contenant le nom du placard
                    return acc;
                }, {});
                setPlacardsInfo(placardsMap);
            }
        };

        fetchPlacards();
    }, []);
    useEffect(() => {
        const fetchProducts = async () => {
            const response = await fetch(`${apiUrl}/products/list`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            if (response.ok) {
                const produits = await response.json();
                const produitsGroupesParPlacards = produits.reduce((acc, produit) => {
                    const placardId = produit.placard.split("/").pop();
                    const placardNom = placardsInfo[placardId] || 'Inconnu'; // Utilise le nom du placard ou 'Inconnu' si non trouvé
                    if (!acc[placardNom]) {
                        acc[placardNom] = [];
                    }
                    acc[placardNom].push(produit);
                    return acc;
                }, {});

                setProducts(Object.entries(produitsGroupesParPlacards).map(([nom, produits]) => ({ nom, produits })));
            }
        };

        if (Object.keys(placardsInfo).length > 0) {
            // Ne cherchez les produits que si les informations des placards ont été chargées
            fetchProducts();
        }
    }, [placardsInfo]); // Cette dépendance garantit que les produits sont cherchés après le chargement des informations des placards

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`${apiUrl}/products/list`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                if (!response.ok) {
                    throw new Error(`Erreur HTTP: ${response.status}`);
                }
                const data = await response.json();
                console.log(data); // Afficher les données pour vérification

                const placardsGroupes = regrouperProduitsParPlacard(data);
                setProducts(placardsGroupes); // Utilise les données transformées
            } catch (error) {
                console.error("Erreur lors de la récupération des produits :", error);
            }
        };

        fetchProducts();
    }, []);



    const handleDelete = async (nom, produitId) => {
        try {
            const url = `${apiUrl}/products/${produitId}`;
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
    
            if (!response.ok) {
                throw new Error('Erreur lors de la suppression du produit');
            }
    
            // Rafraîchissez les produits après la suppression réussie
            const updatedProducts = products.map(placard => {
                if (placard.nom === nom) {
                    return {
                        ...placard,
                        produits: placard.produits.filter(produit => produit.id !== produitId)
                    };
                }
                return placard;
            });
    
            setProducts(updatedProducts);
        } catch (error) {
            console.error(error);
        }
    };
    
    return (
        <Container maxWidth="xxl" style={{ backgroundColor: '#ece0cc', minHeight: '100vh' }}>
            {products.map(({ nom, produits }, index) => (
                <div key={`placard-${index}`}> {/* Utiliser une combinaison du nom et de l'index comme clé */}
                    <Typography variant="h6">{`Placard: ${nom}`}</Typography>
                    <List>
                        {produits.map(produit => (
                            <ListItem key={produit.id}>
                                <ListItemText primary={`${produit.nom}, DLC: ${new Date(produit.expiryDate).toLocaleDateString()}`} />
                                <Button color="secondary" onClick={() => handleDelete(nom, produit.id)}>
                                    Supprimer
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                </div>
            ))}

        </Container>
    );


}

export default Liste;