import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../AuthContext';

function Nav() {
    const [anchorEl, setAnchorEl] = useState(null); // Ajout de l'état pour gérer l'ancrage du menu
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const { logout } = useAuth();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = (path) => {
        navigate(path);
        handleClose(); // Ferme le menu
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        logout(); // cela va supprimer le token et mettre à jour l'état isAuthenticated

        handleNavigate('/'); // Utilise handleNavigate qui ferme également le menu
    };

    return  (
        <>
        {isAuthenticated && (

        <AppBar position="static">
            <Toolbar style={{ justifyContent: 'space-between' }}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 4 }} style={{ textAlign: 'center' }}>
                    Stop Gâspi
                </Typography>
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={handleMenu}
                        >
                            <MenuIcon />
                        </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => handleNavigate('/home')}>Accueil</MenuItem>
                        <MenuItem onClick={() => handleNavigate('/liste')}>Mes produits</MenuItem>
                        <MenuItem onClick={() => handleNavigate('/placard-liste')}>Mes placards</MenuItem>
                        <MenuItem style={{ color: 'red' }} onClick={handleLogout}>Déconnexion</MenuItem>
                    </Menu>
                </Box>

            </Toolbar>
        </AppBar>
        )}
            </>

    );
}

export default Nav;
