import React, { useState, useEffect } from 'react';

const CameraSelector = ({ onCameraSelected }) => {
    const [cameras, setCameras] = useState([]);

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices()
.then(devices => {
    const videoInputs = devices.filter(device => device.kind === 'videoinput');
    console.log(videoInputs);
    videoInputs.forEach(device => {
        console.log(device.label, device.deviceId);
    });
})
.catch(error => {
    console.error('Error accessing media devices.', error);
});
    }, []);

    return (
        <select onChange={(e) => onCameraSelected(e.target.value)}>
            {cameras.map((camera, index) => (
                <option key={camera.deviceId} value={camera.deviceId}>{camera.label || `Camera ${index + 1}`}</option>
            ))}
        </select>
    );
};

export default CameraSelector;
