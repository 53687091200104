import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, TextField, Button, Box, CssBaseline } from '@mui/material';
import { useAuth } from '../AuthContext';

export default function Placard() {
  const [placards, setPlacards] = useState([{ endroit: "" }]);
  const [message, setMessage] = useState('');
  const token = localStorage.getItem('token'); // Récupérer le token JWT
  const navigate = useNavigate();
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;
  const { login } = useAuth();


  const handleSubmit = (event) => {
    event.preventDefault();

    axios.post(`${apiUrl}/placards/new`, placards, {
      headers: {
        'Authorization': `Bearer ${token}`, // Inclure le token JWT
        'Content-Type': 'application/json',
      }

    })
      .then(response => {
        // console.log(response.data);
        setMessage('Placard ajouté avec succès.');
        login(token); // appelez login avec le token lorsque l'utilisateur se connecte avec succès

        navigate('/home'); // Rediriger l'utilisateur vers le formulaire du placard

      })
      .catch(error => {
        console.error('Erreur lors de l\'ajout du placard:', error);
        setMessage('Erreur lors de l\'ajout du placard.');
      });
  };
  const handlePlacardChange = (index, event) => {
    const newPlacards = placards.map((placard, placardIndex) => {
      if (index === placardIndex) {
        return { ...placard, endroit: event.target.value };
      }
      return placard;
    });
    setPlacards(newPlacards);
  };

  const handleAddPlacard = () => {
    setPlacards([...placards, { endroit: "" }]);
  };


  return (
    <Container component="main" maxWidth="xxl" style={{ backgroundColor: '#ece0cc', minHeight: '100vh' }}>
      <Button onClick={() => navigate('/accueil')}>RETOUR</Button>
      <CssBaseline />
      <Box
        sx={{
          paddingTop: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h6">
          Ajout d'un placard, d'une étagère, du frigo ou du congélateur...
        </Typography>
        {message && <Typography color="error" sx={{ mt: 2 }}>{message}</Typography>}
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          {placards.map((placard, index) => (
            <TextField
              key={index}
              autoComplete="placard"
              name={`placard-${index}`}
              required
              fullWidth
              label="Nom du placard ou de l'endroit"
              value={placard.endroit}
              onChange={(e) => handlePlacardChange(index, e)}
              sx={{ mt: 2 }}
            />
          ))}
          <Button onClick={handleAddPlacard} sx={{ mt: 2 }}>
            Ajouter un élément
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Enregistrer le/les placard(s)*
          </Button>
          <Button onClick={() => navigate('/home')}>Ignorer**</Button>
        </Box>
        <Typography variant="caption" gutterBottom>* Vous pourrez ajouter des étagères, des frigos, des congélateurs, etc.</Typography>

      </Box>
      <Typography variant="caption" gutterBottom>** Vous pourrez ajouter des placards plus tard.</Typography>
    </Container>
  );
}

