import React, { useState, useEffect } from 'react';
import Quagga from '@ericblade/quagga2';

const BarcodeScanner = ({ onDetected }) => {
    const [isScanning, setIsScanning] = useState(false);

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            if (videoDevices.length > 1) {
                // Si vous avez plus d'une caméra, vous pouvez choisir celle que vous voulez ici
                // Pour cet exemple, on choisit la deuxième caméra (arrière sur la plupart des téléphones)
                const backCameraId = videoDevices[1].deviceId;

                Quagga.init({
                    inputStream: {
                        name: "Live",
                        type: "LiveStream",
                        target: document.querySelector('#barcode-scanner'),
                        constraints: {
                            deviceId: backCameraId
                        },
                    },
                    decoder: {
                        readers: ["code_128_reader", "ean_reader", "ean_8_reader", "code_39_reader"],
                    },
                    locate: true
                }, (err) => {
                    if (err) {
                        console.error(err);
                        return;
                    }
                    Quagga.start();
                    setIsScanning(true);
                });

                Quagga.onDetected((result) => {
                    onDetected(result);
                    Quagga.stop();
                    setIsScanning(false);
                });

                return () => {
                    Quagga.offDetected(onDetected);
                    Quagga.stop();
                };
            }
        });
    }, [onDetected]);
    const handleStartClick = () => {
        setIsScanning(true);
        Quagga.start(); // Démarrer la capture sur clic
    };

    return (
        <button onClick={handleStartClick} id="button-scan"> <div id="barcode-scanner">
            {!isScanning
            }
            cliquez pour scanner

        </div>
        </button>
    );
};

export default BarcodeScanner;
