import React, { useState, useEffect } from 'react';
import BarcodeScanner from './BarcodeScanner';
import axios from 'axios';
import { Container, Typography, TextField, Button, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CameraSelector from './CameraSelector';

const ProductPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [productName, setProductName] = useState('');
  const [placards, setPlacards] = useState([]);
  const [selectedPlacard, setSelectedPlacard] = useState('');
  const [message, setMessage] = useState('');
  const [isScanning, setIsScanning] = useState(true); // Ajouté pour contrôler l'état de la caméra
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [selectedCameraId, setSelectedCameraId] = useState('');

  const handleCameraSelected = (cameraId) => {
      setSelectedCameraId(cameraId);
  };

  useEffect(() => {
    const fetchPlacards = async () => {
      try {
        const response = await axios.get(`${apiUrl}/placards`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setPlacards(response.data['hydra:member']);
        if (response.data['hydra:member'].length > 0) {
          setSelectedPlacard(response.data['hydra:member'][0].id);
        }
      } catch (error) {
        console.error('Erreur lors du chargement des placards:', error);
      }
    };

    fetchPlacards();
  }, [apiUrl, token]);

  const handleDetected = async (result) => {
    setIsScanning(false); // Arrête la caméra après la détection
    setBarcode(result.codeResult.code);
    await fetchProductData(result.codeResult.code);
  };

  const fetchProductData = async (detectedBarcode) => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://world.openfoodfacts.org/api/v0/product/${detectedBarcode}.json`);
      const data = await response.json();
      setIsLoading(false);
      if (data.status === 1) {
        setProductName(data.product.product_name);
      } else {
        setProductName(''); // Permettre l'ajout manuel si le produit n'est pas trouvé
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Erreur lors de la récupération des données du produit:', error);
      setProductName(''); // Permettre l'ajout manuel en cas d'erreur
    }
  };

  const handleSaveProduct = async () => {
    const productData = {
      nom: productName,
      barcode: barcode,
      expiryDate: expiryDate,
      placard: selectedPlacard,
    };

    try {
      await axios.post(`${apiUrl}/products/add`, productData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      setMessage('Produit enregistré avec succès.');
      navigate('/liste');
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement du produit:', error);
      setMessage('Erreur lors de l\'enregistrement du produit.');
    }
  };

  return (
    <Container maxWidth="xxl" style={{ backgroundColor: '#ece0cc', minHeight: '85vh' }}>
      <Typography variant="h6" style={{ textAlign: 'center', fontFamily: '"Madimi One", sans-serif', paddingTop: '40px', paddingBottom: '20px' }} >
        Ajout d'un produit
      </Typography>
      <div>
            <CameraSelector onCameraSelected={handleCameraSelected} />
            {selectedCameraId && <BarcodeScanner cameraId={selectedCameraId} onDetected={handleDetected} />}
        </div>
      {isScanning && <BarcodeScanner onDetected={handleDetected} />}
      {!isScanning && ( // Affiche le formulaire uniquement si isScanning est false
        <>
          {isLoading && <Typography>Chargement des informations du produit...</Typography>}
          <div>
    
        <Typography variant="h6">Informations sur le produit</Typography>
        <TextField
          label="Nom du produit"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          fullWidth
          sx={{ mt: 2 }}
        />
        <TextField
          label=""
          type="date"
          value={expiryDate}
          onChange={(e) => setExpiryDate(e.target.value)}
          fullWidth
          sx={{ mt: 2 }}
        />
        <TextField
          select
          label="Choisir un placard"
          value={selectedPlacard}
          onChange={(e) => setSelectedPlacard(e.target.value)}
          fullWidth
          sx={{ mt: 2 }}
        >
          {placards.map((placard) => (
            <MenuItem key={placard.id} value={placard.id}>
              {placard.endroit}
            </MenuItem>
          ))}
        </TextField>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveProduct}
          sx={{ mt: 2 }}
        >
          Enregistrer le produit
        </Button>
        {message && <Typography color="error">{message}</Typography>}
        </div>
        </>
      )}
    </Container>
  );
};

export default ProductPage;